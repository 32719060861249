<template>
  <!-- Add User -->
  <div class="modal fade" id="add-user">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons">person_add</span>Novo contato
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Card -->
          <form action="new-friends">
            <div class="form-group">
              <label>Nome</label>
              <input
                class="form-control form-control-lg group_formcontrol"
                v-model="data.newContact.name"
                name="new-chat-title"
                type="text"
              />
            </div>
            <div class="form-group">
              <label>WhatsApp</label>
              <input
                v-maska="['(##) ####-####', '(##) #####-####']"
                id="phone"
                v-model="data.newContact.phone"
                @keyup.enter="saveNewContact"
                class="form-control form-control-lg group_formcontrol"
                name="new-chat-title"
                type="text"
              />
            </div>
          </form>
          <!-- Card -->
          <div
            class="form-row profile_form text-end float-end m-0 align-items-center"
          >
            <!-- Button -->
            <div class="cancel-btn me-4">
              <a
                href="javascript:void(0);"
                data-bs-dismiss="modal"
                v-on:click="
                  data.newContact.phone = '';
                  data.ewContact.name = '';
                "
                aria-label="Close"
                >Cancelar</a
              >
            </div>
            <div class="">
              <button
                type="button"
                class="btn btn-block newgroup_create mb-0"
                v-on:click="saveNewContact"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Conversar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add User -->
</template>

<script>
import { maska } from "maska";
import { useStore } from "../../stores/dataStore";
import { api } from "../../service/api";
import { errorSwal, infoSwal, loading } from "../../helpers/alertSwal";
import Swal from "sweetalert2";
import intlTelInput from "intl-tel-input";
import "../../assets/css/tel-input.css";

export default {
  directives: { maska },
  components: {},
  setup() {
    const data = useStore();

    return { data };
  },
  async mounted() {
    const setDialCode = async (dialcode) => {
      await this.setDialCode(dialcode);
    };
    const input = document.querySelector("#phone");
    const iti = intlTelInput(input, {
      initialCountry: "auto",
      separateDialCode: true,
      geoIpLookup: function (success) {
        $.get(
          "https://ipinfo.io?token=373bd9e83707d7",
          function () {
            //todo
          },
          "json"
        ).always(function (resp) {
          const countryCode = resp && resp.country ? resp.country : "us";
          success(countryCode);
        });
      },
    });
    input.addEventListener("countrychange", function (e) {
      const { dialCode } = iti.getSelectedCountryData();
      setDialCode(dialCode);
    });
    // Busca de contatos para novo chat
    $("#search-new-chat").on("keyup", function () {
      const value = $(this).val().toLowerCase();
      $("#perfect-new-chat ul li").filter(function () {
        $(this).toggle(
          $(this).text().toLowerCase().replace("-", "").indexOf(value) > -1
        );
      });
    });
  },
  data() {
    return {};
  },
  methods: {
    setDialCode(dialcode) {
      this.dialCode = dialcode;
    },
    async saveNewContact() {
      try {
        for (const chn of this.data.channels) {
          if (!chn.isConnected && chn.type === "whatsapp") {
            errorSwal("O Canal do WhatsApp não está conectado.");
            return;
          }
        }
        Swal.showLoading();
        const { data } = await api.post("contacts/new/", {
          contact_type: "whatsapp",
          name: this.data.newContact.name,
          id_api: this.dialCode + this.data.newContact.phone,
        });
        this.data.contacts.push({
          ...data,
          Messages: [],
        });
        infoSwal("Contato criado com sucesso.");
        this.data.newContact.name = "";
        this.data.newContact.phone = "";
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>
<style>
.iti__flag {
  background-image: url("../../assets/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../../assets/img/flags@2x.png");
  }
}
</style>
