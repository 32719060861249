<template>
  <!-- Left Sidebar Menu -->
  <div class="sidebar-menu">
    <div class="logo-col">
      <router-link to="/index"
        ><img src="../../assets/img/logo.png" alt="" width="50"
      /></router-link>
    </div>
    <div class="menus-col">
      <div class="chat-menus">
        <ul>
          <template v-if="!data.userisMobile">
            <li>
              <router-link
                to="/index"
                class="chat-unread"
                v-bind:class="{ blue: unreadChats() }"
              >
                <span class="material-icons">message</span>
                <span>Chats</span>
              </router-link>
            </li>
            <li v-if="data.hasPermission('view-crm')">
              <router-link to="/kanban" class="chat-unread">
                <span class="material-icons">view_kanban</span>
                <span>CRM</span>
              </router-link>
            </li>
            <li>
              <router-link to="/contacts" class="chat-unread">
                <span class="material-icons">contacts</span>
                <span>Contatos</span>
              </router-link>
            </li>
            <li>
              <router-link to="/companies" class="chat-unread">
                <span class="material-icons">store</span>
                <span>Empresas</span>
              </router-link>
            </li>
            <!--
                                <li>
                                    <router-link to="/configuracoes/afiliados" class="chat-unread orange">
                                        <span class="material-icons">attach_money</span>
                                        <span>Afiliados</span>
                                    </router-link>
                                </li>
                                -->
            <li>
              <router-link to="/settings" class="chat-unread">
                <span class="material-icons">settings</span>
                <span>Configurações</span>
              </router-link>
            </li>
          </template>
          <template v-else>
            <li>
              <router-link
                to="/index"
                class="chat-unread"
                v-bind:class="{ blue: unreadChats() }"
              >
                <span class="material-icons">textsms</span>
                <span>Mensagens</span>
              </router-link>
            </li>
            <li>
              <router-link to="/contacts">
                <span class="material-icons">person</span>
                <span>Contatos</span>
              </router-link>
            </li>
            <li>
              <a
                href="#"
                class="newchat"
                data-bs-toggle="modal"
                data-bs-target="#new-chat-with-contact"
              >
                <span class="material-icons">add</span>
                <span>Novo chat</span>
              </a>
            </li>
            <li>
              <router-link to="/companies" class="chat-unread">
                <span class="material-icons">store</span>
                <span>Empresas</span>
              </router-link>
            </li>
            <li>
              <router-link to="/settings">
                <span class="material-icons">settings</span>
                <span>Configurações</span>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
      <div class="bottom-menus">
        <ul>
          <!--
                            <li>
                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#add-new-group">
                                    <span class="material-icons group-add-btn">group_add</span>
                                    <span>Novo grupo</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="add-contacts-btn" data-bs-toggle="modal" data-bs-target="#add-contact">
                                    <i class="fas fa-plus"></i>
                                    <span>Novo contato</span>
                                </a>
                            </li>
                            -->
          <li>
            <a
              href="javascript:void(0);"
              id="dark-mode-toggl"
              v-on:click="clickDarkModeToogle"
              class="dark-mode-toggle"
            >
              <i class="far fa-moon"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              class="chat-profile-icon"
              data-bs-toggle="dropdown"
            >
              <img
                :src="profilePicUrl"
                v-on:error="$event.target.src = getuserNoPhotoUrl"
                alt=""
              />
            </a>
            <div class="dropdown-menu dropdown-menu-end" style="width: 200px">
              <router-link
                to="/sugestoes"
                class="dropdown-item dream_profile_menu"
                >Sugestões
                <span class="edit-profile-icon"
                  ><i class="fas fa-heart"></i></span
              ></router-link>
              <a
                href="https://titanwhats.tawk.help/"
                target="_blank"
                class="dropdown-item dream_profile_menu"
                style="background-color: #f5b342; color: #313a43"
                >Central de ajuda
                <span class="edit-profile-icon"
                  ><i
                    class="fas fa-hands-helping"
                    style="color: #313a43"
                  ></i></span
              ></a>
              <a
                href="https://titanwhats.tawk.help/category/novidades-e-atualiza%C3%A7%C3%B5es"
                target="_blank"
                class="dropdown-item dream_profile_menu"
                >Atualizações
                <span class="edit-profile-icon"><i class="fas fa-rss"></i></span
              ></a>
              <router-link
                to="/settings"
                class="dropdown-item dream_profile_menu"
                >Editar perfil
                <span class="edit-profile-icon"
                  ><i class="fas fa-edit"></i></span
              ></router-link>
              <router-link to="/settings" class="dropdown-item"
                >Opções
                <span class="material-icons">settings</span></router-link
              >
              <router-link
                to="#"
                id="logoutButton"
                v-on:click="disconnectUser"
                class="dropdown-item"
                >Desconectar
                <span class="material-icons"
                  >power_settings_new</span
                ></router-link
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- /Left Sidebar Menu -->
</template>
<script>
import { config } from "../../public/config";
import { router } from "../../router/index";
import { api } from "../../service/api";
import userNoPhotoUrl from "../../assets/img/profile-photo.jpg";
import { useStore } from "../../stores/dataStore";
import { notifyWide } from "../../helpers/notifications.js";
import { receiveCall } from "../../helpers/alertSwal";
import { db } from "../../dbs/db";

export default {
  setup() {
    const data = useStore();

    return { data, db };
  },
  data(instance) {
    return {
      apiURL: config.$api_url + "/",
    };
  },
  async mounted() {
    $(document).ready(async function () {
      if (window.location.hash == "#DarkMode") {
        document.body.classList.add("darkmode");
        localStorage.setItem("darkMode", "enabled");
        const data = await useStore();
        data.darkMode = true;
      }
    });

    // DarkMode with LocalStorage
    const darkMode = localStorage.getItem("darkMode");

    if (darkMode === "enabled") {
      document.body.classList.add("darkmode");
      localStorage.setItem("darkMode", "enabled");
      const data = await useStore();
      data.darkMode = true;
    }
  },
  methods: {
    enableDarkMode() {
      document.body.classList.add("darkmode");
      localStorage.setItem("darkMode", "enabled");
      this.data.darkMode = true;
    },

    disableDarkMode() {
      document.body.classList.remove("darkmode");
      localStorage.setItem("darkMode", null);
      this.data.darkMode = true;
    },
    clickDarkModeToogle() {
      const darkMode = localStorage.getItem("darkMode");

      if (darkMode !== "enabled") {
        this.enableDarkMode();
      } else {
        this.disableDarkMode();
      }
    },

    disconnectUser() {
      //api.post('/logout')
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      localStorage.removeItem("is_admin");
      this.db.delete();
      document.location.reload(true);
    },
    unreadGroups() {
      let retorno = false;
      if (this.data?.contacts?.length === 0) return false;
      const groups = this.data?.contacts?.filter((ctt) =>
        ctt?.id_api.includes("@g.us")
      );
      groups?.map((grp) => {
        if (grp.unreadCount > 0) {
          retorno = true;
        }
      });
      return retorno;
    },
    async unreadChats() {
      let retorno = false;
      if (!this.data?.contacts || this.data?.contacts?.length === 0)
        return false;
      const chats = this.data?.contacts?.filter(
        (ctt) =>
          ctt?.id_api &&
          (ctt?.id_api.includes("@s.whatsapp.net") ||
            ctt?.contact_type === "instagram" ||
            ctt?.contact_type === "telegram_bot" ||
            ctt?.contact_type === "messenger" ||
            ctt?.contact_type === "twitter")
      );
      for (const chat of chats) {
        if (chat.unreadCount > 0) {
          retorno = true;
          break;
        }
      }
      return retorno;
    },
  },
  computed: {
    profilePicUrl() {
      if (!this.data.user.profilePicUrl) {
        return userNoPhotoUrl;
      } else {
        return this.apiURL + this.data.user.profilePicUrl;
      }
    },
    getuserNoPhotoUrl() {
      return userNoPhotoUrl;
    },
  },
};
</script>
