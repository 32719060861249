<script>
import { useStore } from "../../stores/dataStore";
import { api } from "../../service/api";
import moment from "moment";

export default {
  components: {},
  setup() {
    const data = useStore();

    return { data, moment };
  },
  data() {
    return {};
  },
  methods: {
    dispense() {
      $(".close").click();
      api.post("users/dispense-announce/");
    },
    getDate(timestamp) {
      return moment.unix(timestamp).format("DD-MM-YYYY");
    },
  },
  computed: {},
};
</script>

<template>
  <!-- Add User -->
  <div class="modal fade" id="announces">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons">notifications</span>Notificações
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Card -->
          <div
            v-for="announce in data.announces.slice(0, 3)"
            v-bind:key="announce.id"
          >
            <h6 class="title">
              <b>{{ announce.title }}</b>
            </h6>
            <br />
            <span v-html="announce.message"></span>
            <br />
            <span
              style="font-size: 9pt; font-style: italic"
              v-html="moment(announce.createdAt).format('DD/MM/YYYY')"
              class="date"
            ></span>
            <hr />
          </div>
          <!-- Card -->
          <div
            class="form-row profile_form text-end float-end m-0 align-items-center"
          >
            <!-- Button -->
            <div class="cancel-btn me-4">
              <a
                href="javascript:void(0);"
                data-bs-dismiss="modal"
                aria-label="Close"
                >Ver depois</a
              >
            </div>
            <div class="">
              <button
                type="button"
                class="btn btn-block newgroup_create mb-0"
                v-on:click="dispense"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Lidas
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add User -->
</template>
