<script>
import { useStore } from "../../stores/dataStore";

export default {
  setup() {
    return { User: () => useStore() };
  },
  async mounted() {
    await this.User().getUserData();
    // Variables declarations
    const $wrapper = $(".main-wrapper");
    const $pageWrapper = $(".page-wrapper");
    const $sidebarOverlay = $(".sidebar-overlay");
    // Small Sidebar
    if (screen.width >= 992) {
      $(document).on("click", "#toggle_btn", function () {
        if ($("body").hasClass("mini-sidebar")) {
          $("body").removeClass("mini-sidebar");
          $(".subdrop + ul").slideDown();
        } else {
          $("body").addClass("mini-sidebar");
          $(".subdrop + ul").slideUp();
        }
        return false;
      });
      $(document).on("mouseover", function (e) {
        e.stopPropagation();
        if (
          $("body").hasClass("mini-sidebar") &&
          $("#toggle_btn").is(":visible")
        ) {
          const targ = $(e.target).closest(".sidebar").length;
          if (targ) {
            $("body").addClass("expand-menu");
            $(".subdrop + ul").slideDown();
          } else {
            $("body").removeClass("expand-menu");
            $(".subdrop + ul").slideUp();
          }
          return false;
        }
      });
    }
    // Sidebar overlay
    function sidebar_overlay($target) {
      if ($target.length) {
        $target.toggleClass("opened");
        $sidebarOverlay.toggleClass("opened");
        $("html").toggleClass("menu-opened");
        $sidebarOverlay.attr("data-reff", "#" + $target[0].id);
      }
    }
    // Mobile menu sidebar overlay
    $(document).on("click", "#mobile_btn", function () {
      const $target = $($(this).attr("href"));
      sidebar_overlay($target);
      $wrapper.toggleClass("slide-nav");
      $("#chat_sidebar").removeClass("opened");
      return false;
    });
    // Mobile Menu
    $(document).on("click", "#open_msg_box", function () {
      $wrapper.toggleClass("open-msg-box");
      return false;
    });
    // Page wrapper height
    const pHeight = $(window).height();
    $pageWrapper.css("min-height", pHeight);
    $(window).resize(function () {
      const prHeight = $(window).height();
      $pageWrapper.css("min-height", prHeight);
    });
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
<template>
  <!-- Header -->
  <div class="header">
    <!-- Logo -->
    <div class="header-left">
      <router-link to="/admin/index" class="logo">
        <img src="../../assets/img/logo_titan.png" alt="Logo" />
      </router-link>
      <router-link to="/admin/index" class="logo logo-small">
        <img
          src="../../assets/admin_img/logo-small.png"
          alt="Logo"
          width="30"
          height="30"
        />
      </router-link>
    </div>
    <!-- /Logo -->

    <a href="javascript:void(0);" id="toggle_btn">
      <i class="fe fe-text-align-left"></i>
    </a>

    <div class="top-nav-search">
      <form>
        <input type="text" class="form-control" placeholder="Busca" />
        <button class="btn" type="submit"><i class="fa fa-search"></i></button>
      </form>
    </div>

    <!-- Mobile Menu Toggle -->
    <a class="mobile_btn" id="mobile_btn">
      <i class="fa fa-bars"></i>
    </a>
    <!-- /Mobile Menu Toggle -->

    <!-- Header Right Menu -->
    <ul class="nav user-menu">
      <!-- Notifications -->
      <li class="nav-item dropdown noti-dropdown">
        <a
          href="javascript:void(0);"
          class="dropdown-toggle nav-link"
          data-bs-toggle="dropdown"
        >
          <i class="fa fa-bell"></i> <span class="badge badge-pill">3</span>
        </a>
        <div class="dropdown-menu notifications">
          <div class="topnav-dropdown-header">
            <span class="notification-title">Notifications</span>
            <a href="javascript:void(0)" class="clear-noti"> Limpar </a>
          </div>
          <div class="noti-content">
            <ul class="notification-list">
              <li class="notification-message">
                <a href="javascript:void(0);">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="User Image"
                        src="../../assets/admin_img/profiles/avatar-02.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Carlson Tech</span> has
                        approved <span class="noti-title">your estimate</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">4 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:void(0);">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="User Image"
                        src="../../assets/admin_img/profiles/avatar-11.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title"
                          >International Software Inc</span
                        >
                        has sent you a invoice in the amount of
                        <span class="noti-title">$218</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">6 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:void(0);">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="User Image"
                        src="../../assets/admin_img/profiles/avatar-17.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">John Hendry</span> sent a
                        cancellation request
                        <span class="noti-title">Apple iPhone XR</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">8 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:void(0);">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="User Image"
                        src="../../assets/admin_img/profiles/avatar-13.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Mercury Software Inc</span>
                        added a new product
                        <span class="noti-title">Apple MacBook Pro</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">12 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <a href="javascript:void(0);">View all Notifications</a>
          </div>
        </div>
      </li>
      <!-- /Notifications -->

      <!-- User Menu -->
      <li class="nav-item dropdown has-arrow">
        <a
          href="javascript:void(0);"
          class="dropdown-toggle nav-link"
          data-bs-toggle="dropdown"
        >
          <span class="user-img"
            ><img
              class="rounded-circle"
              src="../../assets/admin_img/profiles/avatar-01.jpg"
              width="31"
              alt="Seema Sisty"
          /></span>
        </a>
        <div class="dropdown-menu">
          <div class="user-header">
            <div class="avatar avatar-sm">
              <img
                :src="User().user.profilePicUrl"
                alt="User Image"
                class="avatar-img rounded-circle"
              />
            </div>
            <div class="user-text">
              <h6>{{ User().user.name }}</h6>
              <p class="text-muted mb-0">Administrador</p>
            </div>
          </div>
          <router-link class="dropdown-item" to="/admin/general"
            >Meu perfil</router-link
          >
          <router-link class="dropdown-item" to="/admin/general"
            >Configurações</router-link
          >
          <router-link class="dropdown-item" to="/admin/login"
            >Desconectar</router-link
          >
        </div>
      </li>
      <!-- /User Menu -->
    </ul>
    <!-- /Header Right Menu -->
  </div>
  <!-- /Header -->
</template>
