// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 60px);
  background-color: transparent !important;
}
.admin .sidebar-menu li a.active {
  background-color: #7c2296;
  color: #ffffff;
}
.sidebar-menu ul ul a.active {
  color: #7c2296;
  background-color: unset !important;
}
.sidebar-menu ul li a.active {
  color: #fff;
  background-color: #7c2296;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
